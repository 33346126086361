@import "scss/_base";

.text_area__label-container {
    margin-bottom: 5px;
}

.text_area__label {
    width: 42px;
    height: 22px;
    @include font_size(16px);
    @include letter_spacing();
    font-weight: 600;
}

.text_area__field {
    width: 100%;
    background-color: white;
    border: 1px solid $grey_light;
    border-radius: 7px;
    @include font_size(15px);
    @include letter_spacing();
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 5px;
}

.text_area--width_narrow {
    width: 20%;
    min-width: 70px;
}

.text_area--width_medium {
    width: 40%;
    min-width: 180px;
}

.text_area--width_wide {
    width: 60%;
    min-width: 360px;
}

.text_area--width_fill {
    width: 100%;
}
