@import "scss/_base";

.bot
{
    position: relative;

    display: grid;
    grid-template-rows: 15% min-content 1fr min-content;
    grid-template-columns: 100%;
    width: 100%;
    height: 100%;

    background-color: $grey_superlight;
    overflow: hidden;

    @media screen and (max-height: 1024px)
    {
        grid-template-rows: 12% min-content 1fr min-content;
    }
    // end @media screen and (max-height: 1024px)

    @media screen and (max-height: 896px)
    {
        grid-template-rows: 9% min-content 1fr min-content;
    }
    // end @media screen and (max-height: 896px)

    @media screen and (max-height: 768px)
    {
        grid-template-rows: 6% min-content 1fr min-content;
    }
    // end @media screen and (max-height: 768px)
}
// end .bot

    .bot__progress
    {
        position: absolute;
        top: 0;
        left: 0;

        width: 0;
        height: 6px;

        background-color: $state_positive;
    }
    // end .bot__progress

    .bot__header
    {
        grid-row: 2 / 3;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 $bot_side_padding 2px $bot_side_padding;

        @media screen and (max-width: 1024px)
        {
            padding-right: $bot_side_padding__lt_1024;
            padding-left: $bot_side_padding__lt_1024;
        }
        // end @media screen and (max-width: 1024px)

        @media screen and (max-width: 768px)
        {
            padding-right: $bot_side_padding__lt_768;
            padding-left: $bot_side_padding__lt_768;
        }
        // end @media screen and (max-width: 768px)
    }
    // end .bot__header

        .button
        {
            .bot__header &
            {
                align-self: flex-start;
                margin-bottom: 64px;

                @media screen and (max-height: 1024px)
                {
                    margin-bottom: 32px;
                }
                // end @media screen and (max-height: 1024px)

                @media screen and (max-height: 768px)
                {
                    margin-bottom: 0;
                }
                // end @media screen and (max-height: 768px)
            }
            // end .bot__header .button
        }
        // end .button

    .bot__messages
    {
        position: relative;
        z-index: 1;

        grid-row: 3 / 4;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    // end .bot__messages

        .bot__messages_inner
        {
        }
        // end .bot__messages_inner

    .bot__user_input
    {
        position: relative;
        z-index: 2;

        grid-row: 4 / 5;
    }
    // end .bot__user_input