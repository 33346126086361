@import "scss/_base";

.team_output
{
    display: flex;
    align-items: center;
    min-height: calc(100% - 70px);
    padding: 50px 0 75px 0;

    background-color: $white;
}
// end .team_output

    .site_width
    {
        .team_output &
        {
            max-width: $max_width;
        }
        // end .team_output .site_width
    }
    // end .site_width

        .team_output__column
        {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding-right: $column_gutter;
            padding-left: $column_gutter;
        }
        // end .team_output__column

            .team_output__title
            {
                margin-bottom: 30px;

                color: $protu_navy;

                @include font_size(24px);
                font-weight: 700;
                @include line_height(36px);
                @include letter_spacing();
            }
            // end .team_output__title

            .team_output__label
            {
                display: block;
                margin-bottom: 4px;

                color: $protu_navy;

                @include font_size(12px);
                @include line_height(18px);
                @include letter_spacing();
            }
            // end .team_output__label

            .team_output__select
            {
                width: 50%;

                @include font_size(14px);
            }
            // end .team_output__select

            .team_output__results
            {
                margin-top: 52px;
            }
            // end .team_output__results

                .team_output__results_title
                {
                    color: $protu_navy;

                    @include font_size(16px);
                    font-weight: 700;
                    @include line_height(24px);
                    @include letter_spacing();

                    .team_output__indicators + &
                    {
                        margin-top: 36px;
                    }
                    // end .team_output__indicators + team_output__indicators_title
                }
                // end .team_output__results_title

                .team_output__indicators
                {
                    margin-top: 10px;

                    list-style: none;
                }
                // end .team_output__indicators

                    .team_output__indicator
                    {
                        position: relative;
                        z-index: 1;

                        display: inline-block;
                        width: (100% / 3);
                        padding: 6px 0;

                        .team_output__indicator_marker_name
                        {
                            display: block;
                            width: 100%;

                            color: $protu_navy;

                            @include font_size(12px);
                            font-weight: 700;
                            @include line_height(18px);
                        }
                        // end .team_output__indicator::before

                        &:hover
                        {
                            z-index: 2;
                        }
                        // end .team_output__indicator:hover
                    }
                    // end .team_output__indicator

                        .team_output__indicator_name
                        {
                            color: $protu_purple_dark;

                            @include font_size(15px);
                            font-weight: 700;
                            @include line_height(22px);

                            .team_output__indicator:hover &
                            {
                                cursor: pointer;
                            }
                            // end .team_output__indicator:hover .team_output__indicator_tooltip
                        }
                        // end .team_output__indicator_name

                        .team_output__indicator_tooltip
                        {
                            position: absolute;
                            top: 100%;

                            display: none;
                            width: 360px;
                            padding: 13px 16px;

                            background-color: $white;
                            border: 1px solid $grey_mid;
                            @include border_radius(10px);

                            .team_output__indicator_name:hover ~ &
                            {
                                display: block;
                            }
                            // end .team_output__indicator_name:hover ~ .team_output__indicator_tooltip
                        }
                        // end .team_output__indicator_tooltip

                            .team_output__indicator_member_name
                            {
                                display: inline-block;
                                vertical-align: baseline;

                                color: $protu_navy;

                                @include font_size(12px);
                                font-weight: 700;
                                @include line_height(18px);
                            }
                            // end .team_output__indicator_member_name

                            .team_output__indicator_reiterate_name
                            {
                                display: inline-block;
                                vertical-align: baseline;
                                padding-right: 6px;
                                padding-left: 12px;

                                color: $protu_purple_dark;

                                @include font_size(10px);
                                @include line_height(18px);
                            }
                            // end .team_output__indicator_reiterate_name

                            .team_output__indicator_score
                            {
                                display: inline-block;
                                vertical-align: baseline;

                                color: $protu_navy;

                                @include font_size(12px);
                                font-weight: 700;
                                @include line_height(18px);
                            }
                            // end .team_output__indicator_score

                            .team_output__indicator_description
                            {
                                margin: 5px 0 0 0;

                                color: $protu_navy;

                                @include font_size(11px);
                                @include line_height(16px);
                            }
                            // end .team_output__indicator_description

            .team_output__radar_chart
            {
                display: block;
                width: 100%;
                //background-color: $grey_light;
                //color: $protu_purple_dark;

                @include font_size(16px);
                @include line_height(24px);
            }
            // end .team_output__radar_chart

@media screen and (max-width: $max_width + ($column_gutter * 6))
{
    .team_output
    {
    }
    // end .team_output

        .site_width
        {
            .team_output &
            {
                max-width: none;
            }
            // end .team_output .site_width
        }
        // end .site_width

            .team_output__column
            {
                &:first-child
                {
                    padding-left: $column_gutter * 4;
                }
                // end .team_output__column:first-child

                &:last-child
                {
                    padding-right: $column_gutter * 4;
                }
                // end .team_output__column:last-child
            }
            // end .team_output__column

                .team_output__results
                {
                }
                // end .team_output__results

                    .team_output__indicators
                    {
                    }
                    // end .team_output__indicators

                        .team_output__indicator
                        {
                            width: 50%;
                        }
                        // end .team_output__indicator
}
// end @media screen and (max-width: $max_width + ($column_gutter * 6))

@media screen and (max-width: 768px)
{
    .team_output
    {
    }
    // end .team_output

        .site_width
        {
            .team_output &
            {
            }
            // end .team_output .site_width
        }
        // end .site_width

            .team_output__column
            {
                width: 100%;

                &:first-child
                {
                    padding-right: $column_gutter * 4;
                }
                // end .team_output__column:first-child

                &:last-child
                {
                    margin-top: 60px;
                    padding-left: $column_gutter * 4;
                }
                // end .team_output__column:last-child
            }
            // end .team_output__column

                .team_output__results
                {
                }
                // end .team_output__results

                    .team_output__indicators
                    {
                    }
                    // end .team_output__indicators

                        .team_output__indicator
                        {
                            width: (100% / 3);
                        }
                        // end .team_output__indicator
}
// end @media screen and (max-width: 768px)

@media screen and (max-width: 640px)
{
    .team_output
    {
    }
    // end .team_output

        .site_width
        {
            .team_output &
            {
            }
            // end .team_output .site_width
        }
        // end .site_width

            .team_output__column
            {
                padding-right: $column_gutter * 2 !important;
                padding-left: $column_gutter * 2 !important;
            }
            // end .team_output__column

                .team_output__results
                {
                }
                // end .team_output__results

                    .team_output__indicators
                    {
                    }
                    // end .team_output__indicators

                        .team_output__indicator
                        {
                            width: 50%;
                        }
                        // end .team_output__indicator
}
// end @media screen and (max-width: 640px)

@media screen and (max-width: 448px)
{
    .team_output
    {
    }
    // end .team_output

        .site_width
        {
            .team_output &
            {
            }
            // end .team_output .site_width
        }
        // end .site_width

            .team_output__column
            {
            }
            // end .team_output__column

                .team_output__results
                {
                }
                // end .team_output__results

                    .team_output__indicators
                    {
                    }
                    // end .team_output__indicators

                        .team_output__indicator
                        {
                            width: 100%;
                        }
                        // end .team_output__indicator

                            .team_output__indicator_tooltip
                            {
                                width: 100%;
                            }
                            // end .team_output__indicator_tooltip
}
// end @media screen and (max-width: 448px)

.radar_chart__data_fill {
    .team_output & {
        fill: none;
    }
}

.team_output__loading {
    font-size: 16px;
    margin-top: 20px;
    color: $grey_superdark;
}
