@import "scss/_base";

.edit_message__message_order {
    @include font_size(36px);
    font-weight: 600;
    color: $grey_mid;
    text-align: left;
}

.edit_message__input-group {
  margin-bottom: 10px;
  min-width: 400px;
}

.edit_message__controls {
  text-align: right;
}

.button {
    .edit_message__controls & {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.edit_message__input-group-inline
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
