@import "scss/_base";

.table {
    .users & {
        max-width: 800px;
    }
}

.users__admin-console {
  position: fixed;
  left: 820px;
  top: 67px;
  overflow: scroll;
}

.users__score-higher-than-avarage {
  color: $state_positive;
}

.users__score-higher-than-avarage:after {
  padding-left: 5px;
  content: '▲';
}

.users__score-lower-than-average {
  color: $state_negative;
}

.users__score-lower-than-average:after {
  padding-left: 5px;
  content: '▼';
}

.users__user_detail_row {
    min-height: 40px;
}

.users__user_detail_name {
    font-size: 24px;
}
