@import "scss/_base";

.input--inline {
    display: inline-block;
}

.input__label-container {
    margin-bottom: 5px;
}

.input__label {
    width: 42px;
    height: 22px;
    @include font_size(16px);
    @include letter_spacing();
    font-weight: 600;
}

.input__field {
    width: 100%;
    height: 40px;
    background-color: white;
    border: 1px solid $grey_light;
    border-radius: 7px;
    @include font_size(15px);
    @include letter_spacing();
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 5px;
}

.input--width_narrow {
    width: 20%;
    min-width: 70px;
}

.input--width_medium {
    width: 40%;
    min-width: 180px;
}

.input--width_wide {
    width: 60%;
    min-width: 360px;
}

.input--width_fill {
    width: 100%;
}
