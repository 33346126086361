@import "scss/_base";

.messages__filters
{
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.select
{
    .messages__filters &
    {
        padding-left: 10px;
        padding-right: 10px;
    }
}


/* Fixed width for the "new message" cell, otherwise the table layout changes
 * when "new message" is added to the row.
 */
.messages__table-cell-new-message
{
    width: 100px;
}

.messages__new-message
{
    display: none;
    color: $state_positive;
    font-weight: 600;
}

.table__row:hover .messages__new-message
{
    display: block;
}

.messages__modal_column {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}
