@import "scss/_base";

/* Fake device dimensions */
$screen_width: 375px;
$screen_height: 800px;

.simulator {
    display: flex;
}

    .simulator__input
    {
        padding-bottom: 100px;
        flex-shrink: 0;
    }

        .simulator__controls {
            text-align: center;
            margin-left: 20px;
            margin-bottom: 10px;
            max-width: $screen_width;
            width: $screen_width;
        }

        .simulator__bot_container {
            height: $screen_height;
            max-width: $screen_width;
            margin-right: 20px;
            margin-left: 20px;
            border-radius: 18px;
            box-shadow: 0px 0px 0px 4px $grey_mid;
            overflow: hidden;
        }

.simulator__previous_message_text {
  margin-right: 20px;
}

    .simulator__admin_console {
        padding-bottom: 100px;
        @include font_size(13px);
    }

        .simulator__previous_answer {
          @include font_size(14px);
        }

        .simulator__previous_choice {
          margin-left: 10px;
        }

        .simulator__markers {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
        }

            .simulator__marker_group {
              width: 300px;
              margin: 10px;
            }

                .simulator__marker_name {
                  font-weight: 600;
                }

                .simulator__submarker_name,
                .simulator__marker_name {
                  width: 140px; /* Same as submarker */
                  display: inline-block;
                  white-space: nowrap;
                }

                .simulator__score_went_up,
                .simulator__score_positive {
                  color: $state_positive;
                }

                .simulator__score_went_up:before {
                  content: '▲';
                }


                .simulator__score_went_down,
                .simulator__score_negative {
                  color: $state_negative;
                }

                .simulator__score_went_down:before {
                  content: '▼';
                }

                .simulator__marker,
                .simulator__submarker {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2px;
                }


                .radar_chart__data_outline {
                    .simulator & {
                        display: none;
                    }
                }

                .radar_chart__data_point {
                    .simulator & {
                        display: none;
                    }
                }

                .radar_chart__data_fill {
                    .simulator & {
                        fill: $protu_purple_dark;
                    }
                }
