@import "scss/_base";

.bot__user_input_choices
{
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
}
// end .bot__user_input_choices

    .bot__user_input_choices_title
    {
        padding: 14px $bot_side_padding 0 $bot_side_padding;

        color: $grey_superdark;

        @include font_size(14px);
        font-weight: 700;
        @include line_height(21px);

        &.from_below--enter,
        &.to_below--exit_active,
        &.to_below--exit_done
        {
            opacity: 0;
            visibility: hidden;

            @include translate(0, 20px);
        }
        // end .bot__user_input_choices_title.from_below--enter
        // end .bot__user_input_choices_title.to_below--exit_active
        // end .bot__user_input_choices_title.to_below--exit_done

        &.from_below--enter_active
        {
            @include transition(all, $message_appear_duration / 2);
            transition-delay: ($message_appear_duration / 2) - $test_animation_sequencing;
        }
        // end .bot__user_input_choices_title.from_below--enter_active

        &.to_below--exit_active
        {
            @include transition(all, $message_appear_duration / 2);
            transition-delay: $test_animation_sequencing;
        }
        // end .bot__user_input_choices_title.to_below--exit_active

        &.from_below--enter_active,
        &.from_below--enter_done
        {
            opacity: 1;
            visibility: visible;

            @include translate(0, 0);
        }
        // end .bot__user_input_choices_title.from_below--enter_active
        // end .bot__user_input_choices_title.from_below--enter_done

        @media screen and (max-width: 1024px)
        {
            padding-right: $bot_side_padding__lt_1024;
            padding-left: $bot_side_padding__lt_1024;
        }
        // end @media screen and (max-width: 1024px)

        @media screen and (max-width: 768px)
        {
            padding-right: $bot_side_padding__lt_768;
            padding-left: $bot_side_padding__lt_768;
        }
        // end @media screen and (max-width: 768px)
    }
    // end .bot__user_input_choices_title

    .bot__user_input_choices_list
    {
        min-height: 69px;
        padding-top: 14px;
        padding-bottom: 14px;

        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        .bot__user_input_choices:last-child &
        {
            min-height: calc(55px + 10vh);
            padding-bottom: 10vh;

            @media screen and (max-height: 1024px)
            {
                min-height: calc(55px + 8vh);
                padding-bottom: 8vh;
            }
            // end @media screen and (max-height: 1024px)

            @media screen and (max-height: 768px)
            {
                min-height: calc(55px + 6vh);
                padding-bottom: 6vh;
            }
            // end @media screen and (max-height: 768px)
        }
        // end .bot__user_input_choices:last-child .bot__user_input_choices_list
    }
    // end .bot__user_input_choices_list

        .button
        {
            .bot__user_input_choices_list &
            {
                margin-left: 10px;

                &:first-child
                {
                    margin-left: $bot_side_padding;

                    @media screen and (max-width: 1024px)
                    {
                        margin-left: $bot_side_padding__lt_1024;
                    }
                    // end @media screen and (max-width: 1024px)

                    @media screen and (max-width: 768px)
                    {
                        margin-left: $bot_side_padding__lt_768;
                    }
                    // end @media screen and (max-width: 768px)
                }
                // end .bot__user_input_choices_list .button:first-child

                &:last-child
                {
                    margin-right: $bot_side_padding;

                    @media screen and (max-width: 1024px)
                    {
                        margin-right: $bot_side_padding__lt_1024;
                    }
                    // end @media screen and (max-width: 1024px)

                    @media screen and (max-width: 768px)
                    {
                        margin-right: $bot_side_padding__lt_768;
                    }
                    // end @media screen and (max-width: 768px)
                }
                // end .bot__user_input_choices_list .button:last-child
            }
            // end .bot__user_input_choices_list .button
        }
        // end .button