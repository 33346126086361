@import "scss/_base";

.button
{
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    min-width: 41px;
    min-height: 41px; // Magic number, sorry! 4px border + 16px padding + 21px line-height
    padding: 8px 20px;

    background-color: transparent;
    border: 2px solid transparent;

    &[disabled]
    {
        cursor: default;
    }
    // end .button[disabled]

    &.button--transparent
    {
        padding: 0;
    }
    // end .button.button--transparent

    &.button--hollow
    {
        background-color: $white;

        @include border_radius(20px);
    }
    // end .button.button--hollow

    &.button--solid
    {
        @include border_radius(20px);
    }
    // end .button.button--solid

    &.button--protu_purple_dark
    {
        &.button--hollow
        {
            border-color: $protu_purple_dark;

            &[disabled]
            {
                border-color: mix($protu_purple_dark, $white);
            }
            // end .button.button--protu_purple_dark.button--hollow[disabled]
        }
        // end .button.button--protu_purple_dark.button--hollow

        &.button--solid
        {
            background-color: $protu_purple_dark;

            &[disabled]
            {
                background-color: mix($protu_purple_dark, $white);
            }
            // end .button.button--protu_purple_dark.button--solid[disabled]
        }
        // end .button.button--protu_purple_dark.button--solid
    }
    // end .button.button--protu_purple_dark

    &.button--protu_navy
    {
        &.button--hollow
        {
            border-color: $protu_navy;

            &[disabled]
            {
                border-color: mix($protu_navy, $white);
            }
            // end .button.button--protu_navy.button--hollow[disabled]
        }
        // end .button.button--protu_navy.button--hollow

        &.button--solid
        {
            background-color: $protu_navy;

            &[disabled]
            {
                background-color: mix($protu_navy, $white);
            }
            // end .button.button--protu_navy.button--solid[disabled]
        }
        // end .button.button--protu_navy.button--solid
    }
    // end .button.button--protu_navy

    &.button--grey_superdark
    {
        &.button--hollow
        {
            border-color: $grey_superdark;

            &[disabled]
            {
                border-color: mix($grey_superdark, $white);
            }
            // end .button.button--grey_superdark.button--hollow[disabled]
        }
        // end .button.button--grey_superdark.button--hollow

        &.button--solid
        {
            background-color: $grey_superdark;

            &[disabled]
            {
                background-color: mix($grey_superdark, $white);
            }
            // end .button.button--grey_superdark.button--solid[disabled]
        }
        // end .button.button--grey_superdark.button--solid
    }
    // end .button.button--grey_superdark

    &.button--grey_mid
    {
        &.button--hollow
        {
            border-color: $grey_mid;

            &[disabled]
            {
                border-color: mix($grey_mid, $white);
            }
            // end .button.button--grey_mid[disabled]
        }
        // end .button.button--grey_mid.button--hollow

        &.button--solid
        {
            background-color: $grey_mid;

            &[disabled]
            {
                background-color: mix($grey_mid, $white);
            }
            // end .button.button--grey_mid.button--solid[disabled]
        }
        // end .button.button--grey_mid.button--solid
    }
    // end .button.button--grey_mid

    &.button--small
    {
        min-height: 23px;
        min-width: 23px;
        padding: 0;

        border-width: 1px;
    }
    // end .button.button--small





    &.from_right--enter,
    .bot__message.from_right--enter &
    {
        opacity: 0;
        visibility: hidden;

        @include translate(20px, 0);
    }
    // end .button.from_right--enter
    // end .bot__message.from_right--enter .button

    &.from_right--enter_active,
    .bot__message.from_right--enter_active &
    {
        @include transition(all, $message_appear_duration / 2);
        transition-delay: $message_appear_duration / 2;
    }
    // end .button.from_right--enter_active
    // end .bot__message.from_right--enter_active .button

    &.from_right--enter_active,
    &.from_right--enter_done,
    &.to_right--exit,
    .bot__message.from_right--enter_active &,
    .bot__message.from_right--enter_done &,
    .bot__message.to_right--exit &
    {
        opacity: 1;
        visibility: visible;

        @include translate(0, 0);
    }
    // end .button.from_right--enter_active
    // end .button.from_right--enter_done
    // end .button.to_right--exit
    // end .bot__message.from_right--enter_active .button
    // end .bot__message.from_right--enter_done .button
    // end .bot__message.to_right--exit .button

    &.to_right--exit_active,
    .bot__message.to_right--exit_active &
    {
        @include transition(all, $message_appear_duration / 2);
    }
    // end .button.to_right--exit_active
    // end .bot__message.to_right--exit_active .button

    &.to_right--exit_active,
    &.to_right--exit_done,
    .bot__message.to_right--exit_active &,
    .bot__message.to_right--exit_done &
    {
        opacity: 0;

        @include translate(20px, 0);
    }
    // end .button.to_right--exit_active
    // end .bot__message.to_right--exit_active .button
    // end .bot__message.to_right--exit_done .button





    &.from_below--enter
    {
        opacity: 0;
        visibility: hidden;

        @include translate(0, 20px);
    }
    // end .button.from_below--enter

    &.from_below--enter_active
    {
        @include transition(all, $message_appear_duration / 2);

        @for $i from 1 through 10
        {
            &:nth-of-type(#{$i})
            {
                transition-delay: ($message_appear_duration / 2) + ($test_animation_sequencing * ($i - 1));
            }
            // end .button.from_below--enter_active:nth-of-type(#{$i})
        }
    }
    // end .button.from_below--enter_active

    &.from_below--enter_active,
    &.from_below--enter_done
    {
        opacity: 1;
        visibility: visible;

        @include translate(0, 0);
    }
    // end .button.from_below--enter_active
    // end .button.from_below--enter_done

    &.to_below--exit_active
    {
        @include transition(all, $message_appear_duration / 2);
    }
    // end .button.to_below--exit_active

    &.to_below--exit_active,
    &.to_below--exit_done
    {
        opacity: 0;
        visibility: visible;

        @include translate(0, 20px);
    }
    // end .button.to_below--exit_active
    // end .button.to_below--exit_done




    &.button--is_fake
    {
        position: absolute;
        z-index: 3;
    }
    // end .button.button--is_fake

    &.fake--enter_active
    {
        overflow: hidden;

        @include transition(all, $message_appear_duration / 2);
        transition-property: transform, width, height;
    }
    // end .button.fake--enter_active

    &.fake--enter_done,
    &.null--exit
    {
        opacity: 0;
        visibility: hidden;
    }
    // end .button.fake--enter_done
    // end .button.null--exit

    &.fake--exit_active
    {
        overflow: hidden;

        @include transition(all, $message_appear_duration / 2);
        transition-property: transform, width, height;
        transition-delay: 1ms;
    }
    // end .button.fake--exit_active





    .bot__message.post_fake--enter &,
    .bot__message.post_fake--enter_active &,
    .bot__message.post_fake--exit &,
    .bot__message.post_fake--exit_active &
    {
        opacity: 0;
        visibility: hidden;
    }
    // end .bot__message.post_fake--enter .bot__selection_button
    // end .bot__message.post_fake--enter_active .bot__selection_button
    // end .bot__message.post_fake--exit .bot__selection_button
    // end .bot__message.post_fake--exit_active .bot__selection_button

    .bot__message.post_fake--enter_done &
    {
        opacity: 1;
        visibility: visible;
    }
    // end .bot__message.post_fake--enter_done .bot__selection_button
}
// end .button

    .button__button_caption
    {
        max-height: 100%;

        color: $grey_superdark;

        @include font__content(14px);
        @include line_height(21px);
        text-align: left;

        .button--protu_purple_dark &
        {
            color: $protu_purple_dark;
        }
        // end .button--protu_purple_dark .button__button_caption

        .button--protu_purple_dark[disabled] &
        {
            color: fade($protu_purple_dark, 0.5);
        }
        // end .button--protu_purple_dark[disabled] .button__button_caption

        .button--protu_navy &
        {
            color: $protu_navy;
        }
        // end .button--protu_navy .button__button_caption

        .button--protu_navy[disabled] &
        {
            color: fade($protu_navy, 0.5);
        }
        // end .button--protu_navy[disabled] .button__button_caption

        .button--grey_superdark &
        {
            color: $grey_superdark;
        }
        // end .button--grey_superdark .button__button_caption

        .button--grey_superdark[disabled] &
        {
            color: fade($grey_superdark, 0.5);
        }
        // end .button--grey_superdark[disabled] .button__button_caption

        .button--grey_mid &
        {
            color: $grey_mid;
        }
        // end .button--grey_mid .button__button_caption

        .button--grey_mid[disabled] &
        {
            color: fade($grey_mid, 0.5);
        }
        // end .button--grey_mid[disabled] .button__button_caption

        .button--solid &
        {
            color: $white;
        }
        // end .button--solid .button__button_caption

        .button--solid[disabled] &
        {
            color: fade($white, 0.5);
        }
        // end .button--solid[disabled] .button__button_caption





        .fake--enter &
        {
            white-space: nowrap;
        }
        // end .fake--enter .button__button_caption

        .fake--enter_active &
        {
            white-space: normal;
        }
        // end .fake--enter_active .button__button_caption
    }
    // end .button__button_caption

        .button__button_caption_highlight
        {
            .button--hollow &
            {
                color: $white;
            }
            // end .button--hollow .button__button_caption_highlight

            .button--protu_purple_dark.button--hollow &
            {
                background-color: $protu_purple_dark;
            }
            // end .button--protu_purple_dark.button--hollow .button__button__caption_highlight

            .button--protu_navy.button--hollow &
            {
                background-color: $protu_navy;
            }
            // end .button--navy.button--hollow .button__button__caption_highlight

            .button--grey_superdark.button--hollow &
            {
                background-color: $grey_superdark;
            }
            // end .button--grey_superdark.button--hollow .button__button__caption_highlight

            .button--grey_mid.button--hollow &
            {
                background-color: $grey_mid;
            }
            // end .button--grey_mid.button--hollow .button__button__caption_highlight

            .button--solid &
            {
                background-color: $white;
            }
            // end .button--solid .button__button__caption_highlight

            .button--protu_purple_dark.button--solid &
            {
                color: $protu_purple_dark;
            }
            // end .button--protu_purple_dark.button--solid .button__button__caption_highlight

            .button--protu_navy.button--solid &
            {
                color: $protu_navy;
            }
            // end .button--navy.button--solid .button__button__caption_highlight

            .button--grey_superdark.button--solid &
            {
                color: $grey_superdark;
            }
            // end .button--grey_superdark.button--solid .button__button__caption_highlight

            .button--grey_mid.button--solid &
            {
                color: $grey_mid;
            }
            // end .button--grey_mid.button--solid .button__button__caption_highlight
        }
        // end .button__button__caption_highlight

    .iconography
    {
        .button &
        {
            flex-shrink: 0;

            &:first-child
            {
                margin-right: 8px;
            }
            // end .button .iconography:first-child

            &:last-child
            {
                margin-left: 8px;
            }
            // end .button .iconography:last-child

            &:only-child
            {
                margin-right: 0;
                margin-left: 0;
            }
            // end .button .iconography:only-child
        }
        // end .button .iconography

        [stroke]
        {
            .button--protu_purple_dark &
            {
                stroke: $protu_purple_dark;
            }
            // end .button--protu_purple_dark .iconography [stroke]

            .button--protu_navy &
            {
                stroke: $protu_navy;
            }
            // end .button--protu_purple_navy .iconography [stroke]

            .button--grey_superdark &
            {
                stroke: $grey_superdark;
            }
            // end .button--grey_superdark .iconography [stroke]

            .button--grey_mid &
            {
                stroke: $grey_mid;
            }
            // end .button--grey_mid .iconography [stroke]

            .button--solid &
            {
                stroke: $white;
            }
            // end .button--solid .iconography [stroke]
        }
        // end .iconography [stroke]





        .fake--enter &,
        .fake--exit_active &
        {
            width: 0;

            &:first-child
            {
                margin-right: 0;
            }
            // end .fake--enter .iconography:first-child
            // end .fake--exit_active .iconography:first-child

            &:last-child
            {
                margin-left: 0;
            }
            // end .fake--enter .iconography:last-child
            // end .fake--exit_active .iconography:last-child
        }
        // end .fake--enter  .iconography
        // end .fake--exit_active  .iconography

        .fake--enter_active &
        {
            @include transition(all, $message_appear_duration / 2);
            transition-property: width, margin;
            transition-delay: 1ms; // prevents caption flickering
        }
        // end .fake--enter_active .iconography

        .fake--enter_active &
        {
            width: 12px;

            &:first-child
            {
                margin-right: 8px;
            }
            // end .fake--enter_active .iconography:first-child

            &:last-child
            {
                margin-left: 8px;
            }
            // end .fake--enter_active .iconography:last-child
        }
        // end .fake--enter_active .iconography

        .fake--exit_active &
        {
            @include transition(all, $message_appear_duration / 2);
            transition-property: width, margin;
        }
        // end .fake--exit_active &
    }
    // end .iconography