@import 'scss/_base';

.iconography
{
    position: relative;

    display: inline-block;
    vertical-align: middle;

    img
    {
        position: absolute;
        top: 50%;
        left: 50%;

        @include translate(-50%, -50%);
    }
    // end img

    svg
    {
        position: absolute;
        top: 50%;
        left: 50%;

        max-width: 100%;
        max-height: 100%;

        @include translate(-50%, -50%);
    }
    // end svg
}
// end .iconography

    $sizes: 12, 18, 24, 32, 36;

    @each $size in $sizes
    {
        .iconography--#{$size}
        {
            width: #{$size}px;
            height: #{$size}px;
        }
        // end .iconography--#{$size}
    }

    $rotations: 90, 180, 270;

    @each $rotation in $rotations
    {
        .iconography--rotate_#{$rotation}
        {
            @include rotate($rotation);
        }
        // end .iconography--rotate_#{$size}
    }

    $colours: (
        "white": $white
    );

    @each $name, $value in $colours
    {
        .iconography--colour_#{$name}
        {
            [fill^="#"]
            {
                fill: $value;
            }
            // end [fill^="#"]

            [stroke^="#"]
            {
                stroke: $value;
            }
            // end [stroke^="#"]
        }
        // end .iconography--colour_#{$colour}
    }