@import "scss/_base";

.button {
    .choice_matrix & {
        text-align: right;
        margin-left: 10px;
    }
}

.choice_matrix {
  position: fixed;
  width: calc(100% - 80px); /* subtract padding */
  height: calc(100% - 80px);
  background-color: #efefef;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  left: 0;
  right: 0;
  top: 0;
  color: black;
  pointer-events: all;
  overflow: scroll;
}

.choice_matrix__delete_choice {
    cursor: pointer;
    margin-right: 10px;
    height: 100%;
    vertical-align: middle;
}

.choice_matrix__submarker-title {
  writing-mode: vertical-rl;
  line-height: 14px;
  text-align: center;
}

.choice_matrix__submarker-value-cell {
    padding: 5px;
}

.choice_matrix__choice-submarker-value {
    width: 40px;
    height: 34px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 4px;
    border: 2px solid $grey_mid;
    text-align: center;
    @include font_size(10px);
    font-weight: 600;
}

/* Remove webkit spinners on these small inputs */
.choice_matrix__choice-submarker-value::-webkit-inner-spin-button,
.choice_matrix__choice-submarker-value::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}
