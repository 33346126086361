.protu_character
{
    position: relative;

    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
}
// end .protu_character

    .protu_character__character
    {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        .protu_character--is_default &
        {
            &.protu_character__character--talking_closed,
            &.protu_character__character--excited
            {
                display: none;
            }
            // end .protu_character__character.protu_character__character--talking_closed
            // end .protu_character__character.protu_character__character--excited
        }
        // end .protu_character--is_default .protu_character__character

        .protu_character--is_loading &
        {
            &.protu_character__character--default
            {
                z-index: 2;
            }
            // end .protu_character__character.protu_character__character--default

            &.protu_character__character--talking_closed
            {
                animation: show_and_hide_closed_mouth 400ms linear infinite;
            }
            // end .protu_character__character.protu_character__character--talking_closed

            &.protu_character__character--excited
            {
                display: none;
            }
            // end .protu_character__character.protu_character__character--excited
        }
        // end .protu_character--is_loading .protu_character__character

        .protu_character--is_submitting &
        {
            &.protu_character__character--default,
            &.protu_character__character--talking_closed
            {
                display: none;
            }
            // end .protu_character__character.protu_character__character--default
            // end .protu_character__character.protu_character__character--talking_closed
        }
        // end .protu_character--is_submitting .protu_character__character
    }
    // end .protu_character__character

        svg
        {
            .protu_character__character &
            {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
            }
            // end .protu_character__character svg
        }
        // end svg

@keyframes show_and_hide_closed_mouth
{
    0%
    {
        z-index: 1;
    }

    49%
    {
        z-index: 1;
    }

    50%
    {
        z-index: 3;
    }

    100%
    {
        z-index: 3;
    }
}
