@import "scss/_base";

.bot__message
{
    width: 100%;
    padding-right: $bot_side_padding;
    padding-left: $bot_side_padding;
    @include transition(height, $message_appear_duration / 2);

    &.state--force_squash
    {
        height: 0 !important;
    }
    // end .bot__message.state--force_squash

    &.to_left--exit_active,
    &.to_right--exit_active
    {
        transition-delay: $message_appear_duration / 2;
    }
    // end .bot__message.to_left--exit_active
    // end .bot__message.to_right--exit_active

    /****************************************/
    /****************************************/
    /****************************************/
    /****************************************/
    &:nth-child(odd)
    {
        // background-color: darken(#0F0, 10%);
    }
    // end .bot__message:nth-child(odd)

    &:nth-child(even)
    {
        // background-color: lighten(#0F0, 20%);
    }
    // end .bot__message:nth-child(even)
    /****************************************/
    /****************************************/
    /****************************************/
    /****************************************/

    @media screen and (max-width: 1024px)
    {
        padding-right: $bot_side_padding__lt_1024;
        padding-left: $bot_side_padding__lt_1024;
    }
    // end @media screen and (max-width: 1024px)

    @media screen and (max-width: 768px)
    {
        padding-right: $bot_side_padding__lt_768;
        padding-left: $bot_side_padding__lt_768;
    }
    // end @media screen and (max-width: 768px)
}
// end .bot__message

    .bot__message_inner
    {
        position: relative;

        display: flex;
        align-items: center;
        padding: 2px 0;

        @include transition(padding, $message_appear_duration / 2);

        .bot__message--bot &
        {
            padding-right: 30%;

            @media screen and (max-width: 1024px)
            {
                padding-right: 20%;
            }
            // end @media screen and (max-width: 1024px)
        }
        // end .bot__message--bot .bot__message_inner

        .bot__message--user &
        {
            justify-content: flex-end;
            padding-top: 28px;
            padding-left: 30%;

            .bot__message--user + &
            {
                padding-top: 2px;
            }
            // end .bot__message--user + .bot__message--user .bot__message_inner

            .bot__message--user.to_right--exit_active ~ &
            {
                padding-top: 28px;

                transition-delay: $message_appear_duration / 2;
            }
            // end .bot__message--user.to_right--exit_active + .bot__message--is_next .bot__message_inner

            .bot__message--user.post_fake--exit_active ~ &
            {
                padding-top: 28px;
            }
            // end .bot__message--user.post_fake--exit_active + .bot__message--is_next .bot__message_inner

            .bot__message--user.to_right--enter_active ~ &,
            .bot__message--user.to_right--enter_done ~ &,
            .bot__message--user.post_fake--enter_active ~ &,
            .bot__message--user.post_fake--enter_done ~ &
            {
                padding-top: 2px;
            }
            // end .bot__message--user.to_right--enter_active + .bot__message--is_next .bot__message_inner
            // end .bot__message--user.to_right--enter_done + .bot__message--is_next .bot__message_inner
            // end .bot__message--user.post_fake--enter_active + .bot__message--is_next .bot__message_inner
            // end .bot__message--user.post_fake--enter_done + .bot__message--is_next .bot__message_inner

            @media screen and (max-width: 1024px)
            {
                padding-left: 20%;
            }
            // end @media screen and (max-width: 1024px)
        }
        // end .bot__message--user .bot__message_inner

        .bot__message--is_next &
        {
            .bot__message--user.to_right--enter_active ~ &,
            .bot__message--user.to_right--enter_done ~ &,
            .bot__message--user.post_fake--enter_active ~ &,
            .bot__message--user.post_fake--enter_done ~ &
            {
                padding-top: 8px;
            }
            // end .bot__message--user.to_right--enter_active + .bot__message--is_next .bot__message_inner
            // end .bot__message--user.to_right--enter_done + .bot__message--is_next .bot__message_inner
            // end .bot__message--user.post_fake--enter_active + .bot__message--is_next .bot__message_inner
            // end .bot__message--user.post_fake--enter_done + .bot__message--is_next .bot__message_inner
        }
        // end .bot__message--is_next .bot__message_inner

        .bot__message--user:last-child &
        {
            padding-bottom: 10px;
        }
        // end .bot__message:last-child .bot__message_inner

        .bubble--enter &
        {
            opacity: 0;
            visibility: hidden;
        }
        // end .bubble-enter .bot__message_inner

        .bubble--enter_active &,
        .bubble--enter_done &,
        .to_right--exit &
        {
            opacity: 1;
            visibility: visible;
        }
        // end .bubble--enter_active .bot__message_inner
        // end .bubble--enter_done .bot__message_inner
        // end .to_right--exit .bot__message_inner
    }
    // end .bot__message_inner
