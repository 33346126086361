@import "scss/_base";

.edit_message_modal__wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(50, 50, 50, 0.7);
  overflow: hidden;
}

.edit_message_modal__content {
  width: 940px;
  height: 80%;

  margin: 50px auto;
  padding: 40px;
  background-color: $white;
  position: relative;
  overflow: scroll;
  border-radius: 10px;
  color: #444455;
}
