/**
 * TODO: This file should go away and the styles should be integrated into the
 * actual components.
 */
@import "scss/_base";

.admin {
    @include font_size(14px);

    /**
     * This is temporary.
     * TODO: Figure out how to share protu.scss with admin.
     */
    height: 100%;
    overflow: scroll;

    background-color: $grey_superlight;
}

.admin__nav {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    @include font_size(18px);
    font-variant: all-small-caps;
    border-bottom: 2px solid $protu_purple_light;
}

.admin__list {
    font-weight: 600;
    list-style-type: none;
    margin-left: 20px;
}

.admin__list-item {
    display: inline;
    margin-right: 30px;
}

.admin__list-item-link {
    text-decoration: underline;
}
