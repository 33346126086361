@import "scss/_base";

.bot__user_input_form
{
    padding: 0 $bot_side_padding 14px $bot_side_padding;

    overflow: hidden;

    &:first-child
    {
        padding-top: 14px;
    }
    // end .bot__user_input_form:first-child

    &:last-child
    {
        padding-bottom: 8vh;

        @media screen and (max-height: 768px)
        {
            padding-bottom: 6vh;
        }
        // end @media screen and (max-height: 768px)
    }
    // end .bot__user_input_form:last-child

    @media screen and (max-width: 1024px)
    {
        padding-right: $bot_side_padding__lt_1024;
        padding-left: $bot_side_padding__lt_1024;
    }
    // end @media screen and (max-width: 1024px)

    @media screen and (max-width: 768px)
    {
        padding-right: $bot_side_padding__lt_768;
        padding-left: $bot_side_padding__lt_768;
    }
    // end @media screen and (max-width: 768px)
}
// end .bot__user_input_form

    .bot__user_input_field
    {
        @include remove_appearance();

        display: block;
        width: 100%;
        padding: 8px 20px;

        background-color: $white;
        color: $protu_navy;
        border: 2px solid $grey_mid;
        @include border_radius(20px);

        @include font__content(14px);
        @include line_height(21px);

        &:focus
        {
            border-color: $protu_purple_dark;
        }
        // end .bot__user_input_field

        &.from_below--enter,
        &.to_below--exit_active,
        &.to_below--exit_done
        {
            opacity: 0;
            visibility: hidden;

            @include translate(0, 20px);
        }
        // end .bot__user_input_field.from_below--enter
        // end .bot__user_input_field.to_below--exit_active
        // end .bot__user_input_field.to_below--exit_done

        &.from_below--enter_active
        {
            @include transition(all, $message_appear_duration / 2);
            transition-delay: $message_appear_duration / 2;
        }
        // end .bot__user_input_field.from_below--enter_active

        &.to_below--exit_active
        {
            @include transition(all, $message_appear_duration / 2);
        }
        // end .bot__user_input_field.to_below--exit_active

        &.from_below--enter_active,
        &.from_below--enter_done
        {
            opacity: 1;
            visibility: visible;

            @include translate(0, 0);
        }
        // end .bot__user_input_field.from_below--enter_active
        // end .bot__user_input_field.from_below--enter_done
    }
    // end .bot__user_input_field