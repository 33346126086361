$fonts_path: "../fonts";

// Base fonts
// {
// Circular Std
// {
$circular_font_path: "#{$fonts_path}/circular_std";

/*
        @font-face
        {
            font-family: 'CircularStd';
            src: url('#{$circular_font_path}/CircularStd-Black.eot');
            src: url('#{$circular_font_path}/CircularStd-Black.eot?#iefix') format('embedded-opentype'), url('#{$circular_font_path}/CircularStd-Black.woff') format('woff'), url('#{$circular_font_path}/CircularStd-Black.ttf') format('truetype'), url('#{$circular_font_path}/CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
            font-weight: 800;
            font-style: normal;
        }

        @font-face
        {
            font-family: 'CircularStd';
            src: url('#{$circular_font_path}/CircularStd-BlackItalic.eot');
            src: url('#{$circular_font_path}/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'), url('#{$circular_font_path}/CircularStd-BlackItalic.woff') format('woff'), url('#{$circular_font_path}/CircularStd-BlackItalic.ttf') format('truetype'), url('#{$circular_font_path}/CircularStd-BlackItalic.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
            font-weight: 800;
            font-style: italic;
        }
        */

@font-face {
    font-family: "CircularStd";
    src: url("#{$circular_font_path}/CircularStd-Bold.eot");
    src: url("#{$circular_font_path}/CircularStd-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("#{$circular_font_path}/CircularStd-Bold.woff") format("woff"),
        url("#{$circular_font_path}/CircularStd-Bold.ttf") format("truetype"),
        url("#{$circular_font_path}/CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd")
            format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("#{$circular_font_path}/CircularStd-BoldItalic.eot");
    src: url("#{$circular_font_path}/CircularStd-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("#{$circular_font_path}/CircularStd-BoldItalic.woff") format("woff"),
        url("#{$circular_font_path}/CircularStd-BoldItalic.ttf")
            format("truetype"),
        url("#{$circular_font_path}/CircularStd-BoldItalic.svg#bcc26993292869431e54c666aafa8fcd")
            format("svg");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "CircularStd";
    src: url("#{$circular_font_path}/CircularStd-Medium.eot");
    src: url("#{$circular_font_path}/CircularStd-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("#{$circular_font_path}/CircularStd-Medium.woff") format("woff"),
        url("#{$circular_font_path}/CircularStd-Medium.ttf") format("truetype"),
        url("#{$circular_font_path}/CircularStd-Medium.svg#bcc26993292869431e54c666aafa8fcd")
            format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("#{$circular_font_path}/CircularStd-MediumItalic.eot");
    src: url("#{$circular_font_path}/CircularStd-MediumItalic.eot?#iefix")
            format("embedded-opentype"),
        url("#{$circular_font_path}/CircularStd-MediumItalic.woff")
            format("woff"),
        url("#{$circular_font_path}/CircularStd-MediumItalic.ttf")
            format("truetype"),
        url("#{$circular_font_path}/CircularStd-MediumItalic.svg#bcc26993292869431e54c666aafa8fcd")
            format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "CircularStd";
    src: url("#{$circular_font_path}/CircularStd-Book.eot");
    src: url("#{$circular_font_path}/CircularStd-Book.eot?#iefix")
            format("embedded-opentype"),
        url("#{$circular_font_path}/CircularStd-Book.woff") format("woff"),
        url("#{$circular_font_path}/CircularStd-Book.ttf") format("truetype"),
        url("#{$circular_font_path}/CircularStd-Book.svg#bcc26993292869431e54c666aafa8fcd")
            format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("#{$circular_font_path}/CircularStd-BookItalic.eot");
    src: url("#{$circular_font_path}/CircularStd-BookItalic.eot?#iefix")
            format("embedded-opentype"),
        url("#{$circular_font_path}/CircularStd-BookItalic.woff") format("woff"),
        url("#{$circular_font_path}/CircularStd-BookItalic.ttf")
            format("truetype"),
        url("#{$circular_font_path}/CircularStd-BookItalic.svg#bcc26993292869431e54c666aafa8fcd")
            format("svg");
    font-weight: 400;
    font-style: italic;
}

$circular_std_stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Symbol";

@mixin font__circular_std(
    $size: $base_font_size,
    $weight: normal,
    $style: normal,
    $important: false
) {
    font: {
        family: "CircularStd", $circular_std_stack $important;
        size: rem($size) $important;
        weight: $weight $important;
        style: $style $important;
    }
}
// }
// end Circular Std
// }

// DRY fonts
// {
@mixin font__content(
    $size: $base_font_size,
    $weight: normal,
    $style: normal,
    $important: null
) {
    @include font__circular_std($size, $weight, $style, $important);
}
// }
