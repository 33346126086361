@mixin font_size($pixels)
{
    font-size: $pixels;
    font-size: rem($pixels);
}
// end font_size()

@mixin line_height($pixels)
{
    line-height: $pixels;
    line-height: rem($pixels);
}
// end line_height()

@mixin ghost_element($min_height: 100%)
{
    &::before
    {
        content: '';

        display: inline-block;
        vertical-align: middle;
        @if $min_height { min-height: $min_height }
    }
    // end ghost_element()::before
}
// end ghost_element()

@mixin ghost_element_height($height)
{
    &::before
    {
        min-height: $height;
    }
    // end ghost_element_height()::before
}
// end ghost_element_height()

@mixin column_gutter($gutter: $column_gutter)
{
    padding-right: $gutter;
    padding-left: $gutter;
}
// end column_gutter()

@mixin border_radius($radius)
{
    border-radius: $radius;
}
// end border_radius()

@mixin transition($property: all, $duration: 75ms, $ease: $global_easing_function)
{
    transition: $property $duration $ease;
}
// end transition()

@mixin transition_reset()
{
    transition: none;
}
// end transition()

@mixin transform($transforms)
{
    transform: $transforms;
}
// end transform()

@mixin scale($ratio)
{
    transform: scale($ratio);
}
// end scale()

@mixin translate($horizontal, $vertical)
{
    transform: translate($horizontal, $vertical);
}
// end translate()

@mixin rotate($deg)
{
    @include transform(rotate(#{$deg}deg));
}
// end rotate()

@mixin transform_origin($origin)
{
    transform-origin: $origin;
}

@mixin letter_spacing($value: -0.02em)
{
    letter-spacing: $value;
}
// end negative_letter_spacing()

@mixin remove_appearance()
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}