@import "scss/_base";

.button--inline {
    display: inline-block;
}

.button--width_narrow {
    width: 20%;
    min-width: 70px;
}

.button--width_medium {
    width: 40%;
    min-width: 180px;
}

.button--width_wide {
    width: 60%;
    min-width: 360px;
}

.button--width_fill {
    width: 100%;
}

.button--variant_delete {
    background-color: $white;
    /* Use default color */
}

.button--variant_save {
    background-color: $state_neutral;
    border: 1px solid $state_neutral;
    color: $white;
}

.button--variant_cancel {
    background-color: $white;
    border: 1px solid $grey_mid;
    color: $black;
}

.button--variant_delete {
    background-color: $state_negative;
    border: 1px solid $state_negative;
    color: $white;
}

.button--variant_action {
    background-color: $protu_purple_dark;
    color: $white;
}

.button__label-container {
    margin-bottom: 5px;
}

.button__label {
    width: 42px;
    height: 22px;
    @include font_size(16px);
    @include letter_spacing();
    font-weight: 600;
}

.button__field {
    height: 35px;
    border-radius: 7px;
    @include font_size(15px);
    @include letter_spacing();
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 5px;
    font-weight: 600;
}
