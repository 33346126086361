@import "scss/_normalise",
        "scss/_base";

html
{
    height: 100%;
    box-sizing: border-box;

    overflow: hidden;
}
// end html

*,
*::before,
*::after
{
    box-sizing: inherit;
}
// end wildcard
// end wildcard::before
// end wildcard::after

:focus,
:active
{
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
// end :focus
// end :active

.site_width
{
    width: 100%;
    margin: 0 auto;
}
// end .site_width

.scroll_locked
{
    overflow: hidden !important;

    touch-action: none;

    @for $scrollbar_width from 1 through 30
    {
        [data-scrollbar-width="#{$scrollbar_width}"] &
        {
            padding-right: #{$scrollbar_width + 'px'};
        }
        // end [data-scrollbar-width="#{$scrollbar_width}"] .scroll_locked
    }
}
// end .scroll_locked

body
{
    height: 100%;

    color: $protu_navy;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @include font__content(0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// end body

    #root
    {
        min-height: 100%;
    }
    // end #root

        .protu
        {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100%;
        }
        // end .protu
