@import "scss/_base";

.radar_chart__legend {
    font-weight: 600;
    fill: $grey_superdark;
    font-size: 14px;
}

.radar_chart__legend_values {
    font-weight: 600;
    fill: $grey_superdark;
}

.radar_chart__legend_border {
    stroke: $protu_purple_mid;
    stroke-width: 4px;
    fill: white;
}

.radar_chart__levels {
    stroke: $grey_light;
    stroke-width: 2px;
}

.radar_chart__data_fill {
}

.radar_chart__data_outline {
    fill: rgba(0,0,0,0);
    stroke-width: 4px;
}

.radar_chart__data_point {
    fill: white;
    stroke-width: 4px;
}
