@import "scss/_base";

.admin_login
{
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: auto;
    margin-top: 20px;
}

.admin_login__form
{
    max-width: 480px;
    margin: auto;
}

.admin_login__logo {
    width: 100px;
    margin-bottom: 20px;
}

.admin_login__header {
    float: right;
    @include font_size(48px);
    margin-top: 10px;
    margin-right: 10px;
}
