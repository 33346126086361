@import "scss/_base";

.replace-previous-response
{
    .bot__message_text & {
        color: $protu_purple_dark;
    }
}

.bot__message_inner_content
{
    position: relative;
    z-index: 1;
}
// end .bot__message_inner_content

    .bot__message_text
    {
        position: relative;
        z-index: 1;

        display: inline-block;
        margin: 0;
        padding: 10px 20px;

        @include font_size(14px);
        @include line_height(21px);

        &::before
        {
            content: '';

            position: absolute;
            z-index: -1;
            top: 0;

            display: block;
            width: 0;
            height: 0;

            box-shadow: 0 6px 10px 0 fade($protu_purple_dark, 0.93);

            .bot__message--bot &
            {
                left: 0;

                background-color: $white;
                @include border_radius(0 20px 20px 20px);
            }
            // end .bot__message--bot .bot__message_text::before

            .bot__message--user &
            {
                right: 0;

                background-color: $protu_purple_dark;
                @include border_radius(20px 0 20px 20px);
            }
            // end .bot__message--user .bot__message_text::before

            .bubble--enter &
            {
                width: 0;
                height: 0;
            }
            // end .bubble--enter .bot__message_text::before

            .bubble--enter_active &,
            .bubble--enter_done &,
            .to_left--exit &,
            .to_right--exit &
            {
                width: 100%;
                height: 100%;

                @include transition(all, $message_appear_duration / 2);
            }
            // end .bubble--enter_active .bot__message_text::before
            // end .bubble--enter_done .bot__message_text::before
            // end .to_left--exit .bot__message_text::before
            // end .to_right--exit .bot__message_text::before
        }
        // end .bot__message_text::before

        .bot__message--bot.bubble--enter &
        {
            color: fade($protu_navy, 1);
        }
        // end .bot__message--bot.bubble--enter .bot__message_inner

        .bot__message--user.bubble--enter &
        {
            color: fade($white, 1);
        }
        // end .bot__message--user.bubble--enter .bot__message_text

        .bubble--enter_active &
        {
            @include transition(color, $message_appear_duration / 2);
            transition-delay: $message_appear_duration / 2;
        }
        // end .bubble--enter_active .bot__message_inner

        .bot__message--bot.bubble--enter_active &,
        .bot__message--bot.bubble--enter_done &,
        .bot__message--bot.to_left--exit &,
        .bot__message--bot.to_right--exit &
        {
            color: $protu_navy;
        }
        // end .bot__message--bot.bubble--enter_active .bot__message_inner
        // end .bot__message--bot.bubble--enter_done .bot__message_inner
        // end .bot__message--bot.to_left--exit .bot__message_inner
        // end .bot__message--bot.to_right--exit .bot__message_inner

        .bot__message--user.bubble--enter_active &,
        .bot__message--user.bubble--enter_done &,
        .bot__message--user.to_left--exit &,
        .bot__message--user.to_right--exit &
        {
            color: $white;
        }
        // end .bot__message--user.bubble--enter_active .bot__message_inner
        // end .bot__message--user.bubble--enter_done .bot__message_inner
        // end .bot__message--user.to_left--exit .bot__message_inner
        // end .bot__message--user.to_right--exit .bot__message_inner

        .to_left--exit &,
        .to_right--exit &
        {
            opacity: 1;

            @include translate(0, 0);
        }
        // end .to_left--exit .bot__message_text
        // end .to_right--exit .bot__message_text

        .to_left--exit_active &
        {
            opacity: 0;

            @include translate(-20px, 0);

            @include transition(all, $message_appear_duration / 2);
        }
        // end .to_left--exit_active .bot__message_text

        .to_right--exit_active &
        {
            opacity: 0;

            @include translate(20px, 0);

            @include transition(all, $message_appear_duration / 2);
        }
        // end .to_right--exit_active .bot__message_text
    }
    // end .bot__message_text

    .button
    {
        .bot__message_inner_content &
        {
            position: absolute;
            top: 50%;
            left: 100%;

            margin-top: -12px;
            margin-left: 25px;
            padding-right: 0;
            padding-left: 0;

            // @include translate(0, -50%);
        }
        // end .bot__message_inner_content .button
    }
    // end .button
