@import "scss/_base";

.table {
    text-align: left;
    width: 100%;
    border-spacing: 0;
}

.table__head-row {
    text-align: left;
    border-top: 2px solid $protu_purple_mid;
    border-bottom: 2px solid $protu_purple_mid;
    background-color: $protu_purple_light;
}

.table__head-cell {
    font-weight: 600;
    text-align: left;
    padding: 4px 10px;
}

.table__row {
    border-bottom: 1px solid $protu_purple_mid;
    min-height: 30px;
}

.table__row:hover
{
    background-color: $protu_purple_dark;
    color: $protu_purple_light;
    cursor: pointer;
}

.table__cell
{
    padding: 4px 10px;
}
