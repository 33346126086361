// colours
// {
    // greyscale
    // {
        $white: #FFFFFF;
        $grey_superlight: #FBFBFF;
        $grey_light: #E7E7E7;
        $grey_mid: #8C9BAE;
        $grey_superdark: #494949;
        $black: #000000;
    // }

    // brand
    // {
        // why did this change to line 16? $protu_purple_dark: #7F90F8;
        $protu_purple_light: #EDF1FF;
        $protu_purple_mid: #C1BCFC;
        $protu_purple_dark: #796EFF;
        $protu_purple_darker: #665AF3;

        $protu_navy: #002554;
    // }

    // state
    // {
        $state_positive: #25E8C8;
        $state_neutral: #6BE6F9;
        $state_warning: #FFE200;
        $state_negative: #FF7784;
    // }

    // social media
    // {
    // }
// }

// structural
// {
    // general
    // {
        $max_width: 1140px;
        $column_width_pc: 100% / 12;
        $column_width_px: $max_width / 12;
        $column_gutter: 10px;
    // }

    // company site
    // {
        $company_max_width: 960px;
        $company_column_width_px: $company_max_width / 12;
    // }

    // bot
    // {
        $bot_side_padding: 12%;
        $bot_side_padding__lt_1024: 8%;
        $bot_side_padding__lt_768: 25px;
    // }

    $border_width: 2px;

    $base_font_size: 13px;

    $global_button_height: 40px;

    $message_appear_duration: 800ms; // 800ms
    $modal_appear_duration: 800ms;
    $test_animation_sequencing: 50ms; // 50ms

    $global_easing_function: cubic-bezier(0.645, 0.045, 0.355, 1);

    $sidebar_width: 140px;
// }
